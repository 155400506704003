import React from 'react';
import { Section, Container } from '@components/Layout';
import styled from 'styled-components';
import ImageVideo from '@components/ImageVideo';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { down } from '@helper/breakpoint.helper';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';


const StyledLogo = styled.img`
    right: 0;
    top: 30px;
    width: 50px;
    position: absolute;
    z-index: 10;
    
        ${down('md')}
{
top: 0;
}
`;

const StyledBackLink = styled.span`
    padding: 8px 10px;
    font-family: Boston-Black, sans-serif;
    font-size: 32px;
    position: absolute;
    top: 30px;
    color: #ffffff;
    left: 0;
    z-index: 2;
    
    transition: all .3s ease;
    
    &:hover{
        background: #44e543;
        color: black;

    }
    
    ${down('md')}
      {
      top: 0;
      }
    `;

const StyledHeader = styled.h1`
color: white;
font-size: 170px;
line-height: 230px;
margin: 0;
padding-top: 90px;

font-family: 'Boston-Bold', sans-serif;

${down('md')}
{
font-size:60px;
line-height: 60px;
text-align: center;
}
`;

const StyledText = styled.p`
color: gray;
font-size: 20px;
font-family: 'Boston-Thin', sans-serif;
padding: 20px;
`;

const StyledBackButton = styled.a`
padding: 10px 15px;
    border: 3px solid #44e543;
    position: relative;
    font-weight: bold;

    color: #44e543;

    span {
        margin: 0;
        z-index: 1;
        position: relative;
    }

    &:after {
        content: " ";
        background: #44e543;
        height: calc(100% + 2px);
        position: absolute;
        width: 0;
        left: -1px;
        top: -1px;
        transition: width .3s ease;
    }

    &:hover {
        color: black;
        span {
            color: black;
        }
        &:after {
            width: calc(100% + 2px);
        }
    }
`;

export const StyledContentImage = styled.img`
width: auto;
height: 100%; 
position: absolute;
left: 50%;
transform: translateX(-50%);

`;

const StyledAccentImage = styled.img`
width: 120px;
position: relative;
left: 50%;
transform: translateX(-50%);
z-index: 10;

${down('md')}
{
left: 50%;
position: relative;
transform: translateX(-50%);
margin: 0;
}
`;

const DetailPage = ({ data }:any) => {
  const item = data.allSitePage.edges[0].node.context;
  return (
    <Section>
      <Helmet>
        <title>pixelart - WOW Shop</title>
        <meta name="wow shop" content="Eine Sammlung an experimentellen Projekten." />
        <html lang="de" />
      </Helmet>

      <Container>

        <AniLink
          to="/"
          hex="#000"
          paintDrip
        >
          <StyledBackLink>
            Zurück
          </StyledBackLink>
        </AniLink>

        <AniLink
          to="/"
          hex="#000"
          paintDrip
          aria-label="home"
        >
          <StyledLogo className="" src="/pix_logo.svg" alt="" />
        </AniLink>

      </Container>

      <Container>
        <div className="lines columns">
          <div className="column is-one-quarter">
            <div className="line" />
          </div>
          <div className="column is-one-quarter">
            <div className="line" />
          </div>
          <div className="column is-one-quarter">
            <div className="line" />
          </div>
          <div className="column is-one-quarter">
            <div className="line" />
          </div>
        </div>

        <div className="columns">
          <div className="column is-full-mobile is-narrow">
            <StyledHeader>
              {item.title}
            </StyledHeader>
            <StyledAccentImage className="" src="/dash.svg" alt="" />
          </div>
        </div>

        <div className="columns">
          <div className="column is-offset-6 is-full-mobile is-half-tablet is-half-desktop ">
            <div className="columns is-center is-multiline">
              <div className="column is-centered is-full">
                <StyledText>
                  {item.description}
                </StyledText>
              </div>
              <div className="column is-full">
                <div className="columns is-mobile is-multiline is-centered ">
                  <div className="column is-narrow">
                    <StyledBackButton
                      href={item.link}
                      className=" button  "
                    ><span>Zum Projekt</span>
                    </StyledBackButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="column is-two-thirds">
          <div className="columns is-multiline">

            {item.contentPaths.map((element, index) => (
              <div key={element.slug} className={`column is-half${index === 1 || index === 4 ? ' is-offset-6' : ''}${index === 2 || index === 5 ? ' is-offset-12' : ''}`}>
                <ImageVideo
                  type={element.type}
                  path={element.contentPath}
                />
              </div>
            ))}
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default DetailPage;
export const query = graphql`
query($path: String!) {
  allSitePage(filter: {path: { eq: $path} }){
  edges {
    node {
      context {
        slug
        title
        description
        thumbnailPath
        link
        contentPaths {
          contentPath
          type
          }
        }
      }
    }
  }
}
`;
