import React from 'react';
import styled from 'styled-components';
import { down, up } from '@helper/breakpoint.helper';
import theme from '@/theme';


const StyledVideo = styled.video`
     position: relative;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translate(-50%, -50%);
    object-fit: contain;
    width: auto;
    height: auto;

    max-width: 100%;
`;

const StyledContentImageContainer = styled.div`
height: 20vw; 
transition: all .3s ease;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  overflow: hidden;
  z-index: 5;

&:hover{
  filter: grayscale(0);
  transform: scale(1.08);
}

&:nth-child( 4n - 2){
margin-left: 200px;
}

${down('md')}
{
height: ${theme.components.imageContainer.height[0]};
padding: 15px;
}

`;

const StyledImageVideoContainer = styled.div`
position: relative;

&:hover > .styled-content-image-container{
position: relative;
}

${up('md')}
{
&:hover > .styled-offset-text{
left: 35%;
top: 35%;
width: 100%;
height: 100%;
    animation: bg 2s linear infinite;

}
}

`;


const StyledContentImage = styled.img`
    position: relative;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translate(-50%, -50%);
    object-fit: contain;
    width: auto;
    height: auto;

    max-width: 100%;
`;

const StyledOffsetText = styled.div`

position: absolute;

transform: translate(-50%, -50%);
transition: all .3s ease-in-out;
width: 0;
height: 0;
left: 50%;
top: 50%;
z-index: -1;

background-color: #44e543;
background-image: url('/patternio.jpg');
background-size: 200%;
background-blend-mode: multiply;

`;


export default class ImageVideo extends React.Component {
  private videoRef: any;

  constructor(props: any) {
    super(props);
    this.playVideo = this.playVideo.bind(this);
    this.stopVideo = this.stopVideo.bind(this);
    this.videoRef = React.createRef();
  }

  playVideo() {
    this.videoRef.current.play();
  }

  stopVideo() {
    this.videoRef.current.pause();
  }

  render() {
    const { type } = this.props;
    const { path } = this.props;
    const { videoRef } = this;

    if (type === 'video') {
      return (
        <StyledImageVideoContainer>
          <StyledContentImageContainer
            className="styled-content-image-container"
            onMouseEnter={this.playVideo}
            onMouseLeave={this.stopVideo}
          >
            <StyledVideo poster="/drone.gif" ref={videoRef} loop>
              <source src={path} type="video/mp4" />
              Your browser does not support the video tag.
            </StyledVideo>
          </StyledContentImageContainer>
          <StyledOffsetText className="styled-offset-text" />
        </StyledImageVideoContainer>

      );
    }
    return (
      <StyledImageVideoContainer>
        <StyledContentImageContainer className="styled-content-image-container">
          <StyledContentImage className="content-item__image" src={path} alt="" />
        </StyledContentImageContainer>
        <StyledOffsetText className="styled-offset-text" />
      </StyledImageVideoContainer>

    );
  }
}
